<template>
  <v-row>
    <v-col cols="12" md="12">
      <v-row>
        <v-col class="pt-2">
          <span
            :class="[
              getStatus === true ? 'dot-online' : 'dot-online dot-task-active',
            ]"
            :title="getStatus === true ? 'Online' : 'idle'"
            v-if="getStatus === true ? 'Online' : 'idle'"
          />
          <v-btn
            class="float-right"
            height="30"
            :id="userIdEmployee"
            text
            x-small
            @click="refresh(userIdEmployee, false)"
          >
            <v-icon class="icon-color"> mdi-refresh </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <Loader v-if="!instant_capture_src[userIdEmployee]" />
      <img
        v-else
        style="cursor: pointer; width: 100%; height: 310px"
        :src="instant_capture_src[userIdEmployee]"
        @click="showModal(userIdEmployee, userName)"
      />
      <v-dialog v-model="dialog" max-width="1000">
        <v-card>
          <v-card-title class="headline white lighten-2">
            <v-row>
              <v-col class="d-flex" cols="12" md="4">
                {{ user_monitor_name }}
              </v-col>
              <v-col cols="12" md="2" offset-md="6">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      text
                      x-small
                      @click="real_time_req(userIdEmployee)"
                      v-bind="attrs"
                      v-on="on"
                      :disabled="realTimeMonitoringStatus"
                    >
                      <v-icon class="icon-color"> mdi-desktop-mac </v-icon>
                    </v-btn>
                  </template>
                  <span>Real Time Monitoring</span>
                </v-tooltip>
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      text
                      v-bind="attrs"
                      v-on="on"
                      x-small
                      @click="refresh(userIdEmployee + '-dummy', true)"
                    >
                      <v-icon class="icon-color"> mdi-refresh </v-icon>
                    </v-btn>
                  </template>
                  <span>Instant Capture</span>
                </v-tooltip>
                <v-btn text x-small @click="cross_modal(userIdEmployee)">
                  <v-icon class="icon-color"> mdi-close </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-divider></v-divider>
          <v-card-text>
            <img
              class="img-style col-12"
              :src="instant_image"
              v-if="instant_capture"
            />
            <Loader v-if="(!realTimeImg && real_time) || loading" />
            <img class="img-style col-12" :src="realTimeImg" v-if="real_time" />
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
import moment from "moment";
import { mapState } from "vuex";
export default {
  name: "MultipleRealTime",
  components: {
    Loader: () =>
      import("@/components/TeamMembers/RealTimeMonitor/CreateVideoLoader"),
  },
  props: ["userIdEmployee", "userName", "getStatus"],
  data() {
    return {
      dialog: false,
      timeForRealTimeMonitoring: null,
      realTimeMonitoringStatus: false,
      instant_capture_src: [],
      user_id: null,
      call: null,
      modal_open: false,
      btn_real: true,
      success: false,
      btn_img: true,
      realTimeImg: "",
      instant_capture: true,
      real_time: false,
      loader: false,
      user_monitor_id: null,
      user_monitor_name: null,
      loading: false,
      instant_image: "",
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
  },
    watch:{
        dialog : function (value) {
            if(value == false){
                this.stopRealTimeMonitor()
            }
        }
    },
  created() {
    const company = this.$store.state.custom.companies_list.find(
      (c) => c.id === this.$route.params.id
    );
    if (company) {
      if (company.user_status == "employee") {
        if (this.$store.state.custom.lead_team_members.length == 0) {
          this.$router.push({ name: "Dashboard" });
        }
      }
    }
  },
  mounted() {
    this.user_id = this.userIdEmployee;
    // this.$socket.emit("stop_real_time_monitor", this.user_id);
    this.$root.$on("instant_capture", (data, userId = null) => {
      if (this.user_id !== null) {
        this.instant_capture_src[this.user_id] = data;
      } else {
        this.$socket.emit("stop_real_time_monitor", this.user_id);
      }
    });
    this.$root.$on("send_member_id", (data) => {
      this.user_id = data;
    });

    this.instant_capture_req();
    this.$root.$on("real_time_monitor", (data, userId = null) => {
      this.loader = true;
      if (this.userIdEmployee !== null && this.userIdEmployee === userId) {
        this.loader = false;
        this.realTimeImg = data;
      }
    });
    window.addEventListener("beforeunload", function (e) {
      e.preventDefault();
      let real_id = this.$store.state.custom.real_time_user;
      this.$socket.emit("stop_real_time_monitor", real_id);
    });
  },
  sockets: {
    real_time_monitoring(image, user_id) {
      let btn_ref = this.$refs.real_monitor;
      if (btn_ref) {
        this.$disabled_btn(btn_ref);
        btn_ref.removeAttribute("disabled");
        btn_ref.innerHTML = "";
      }
      this.$root.$emit("real_time_monitor", image, user_id);
    },
  },
  methods: {
    stopRealTimeMonitor(){
        this.$socket.emit("stop_real_time_monitor", this.userIdEmployee);
    },
    memberStatus(id) {
      let employees_online = this.$store.state.custom.all_store_socket_employee.find(
        (data) => data.user_id === id
      );
      if (employees_online) return employees_online.task_running;
    },
    resetModal(userIdEmployee) {
      this.$socket.emit("stop_real_time_monitor", userIdEmployee);
    },
    closeModal(userIdEmployee) {
      this.$socket.emit("stop_real_time_monitor", userIdEmployee);
    },
    cross_modal(userIdEmployee) {
      this.$socket.emit("stop_real_time_monitor", userIdEmployee);
      this.dialog = false;
      clearTimeout(this.timeForRealTimeMonitoring);
      this.realTimeMonitoringStatus = false;
      this.loader = false;
    },
    showModal(userIdEmployee, userName) {
      this.dialog = true;
      this.user_monitor_id = userIdEmployee;
      this.user_monitor_name = userName;
    },

    real_time_req(userIdEmployee) {
      this.realTimeMonitoringStatus = true;
      this.loading = true;
      this.instant_capture = false;
      this.real_time = true;
      this.loading = false;
      this.$store.commit("custom/set_real_time_user", userIdEmployee);
      let company_id = this.$route.params.id;
      let user_id = this.user.id;
      let memberid = userIdEmployee;
      let auth_token = this.user.auth_token;
      this.$socket.emit("real_time_monitoring", {
        user_id,
        memberid,
        company_id,
        auth_token,
      });
      this.timeForRealTimeMonitoring = setTimeout(() => {
        this.cross_modal();
      }, 60000);
    },
    refresh(id, flag) {
      this.instant_capture = true;
      this.real_time = false;
      // let ele = document.getElementById(id);
      // ele.classList.add("fa-spin");
      id = id.split("-")[0];
      if (flag === true) {
        this.$socket.emit("stop_real_time_monitor", id);
        this.instant_capture_req(id, true);
        this.forsinglerefresh(id);
      } else {
        this.$socket.emit("stop_real_time_monitor", id);
        this.instant_capture_req(id);
        this.forsinglerefresh(id);
      }
    },
    forsinglerefresh(userId) {
      this.$root.$on("instant_capture", (data, userId = null) => {
        if (this.user_id !== null) {
          this.instant_capture_src[this.userId] = data;
        } else {
          this.$socket.emit("stop_real_time_monitor", userId);
        }
      });
    },
    instant_capture_req(id = null, large = false) {
      let member_id = this.userIdEmployee;
      let user_id = this.user.id;
      let auth_token = this.user.auth_token;
      if (id) member_id = id;
      this.$socket.emit(
        "instant capture",
        {
          user_id,
          member_id,
          company_id: this.$route.params.id,
          auth_token,
        },
        (response, user_id) => {
          this.instant_image = response;
          if (large === true) {
            member_id = member_id + "-dummy";
          }
          member_id = member_id.split("-")[0];
          if (large === true) {
            member_id = member_id + "-dummy";
            // document.getElementById(member_id).classList.remove("fa-spin");
          } else {
            // document.getElementById(member_id).classList.remove("fa-spin");
          }
          member_id = member_id.split("-")[0];
          this.$root.$emit("send_member_id", member_id);
          this.$root.$emit("instant_capture", response, user_id);
          if (response) {
            const filterOnlineMembers = this.$store.state.custom.groupMontring.map(
              (member) => {
                var temp = Object.assign({}, member);
                if (member.id === user_id) {
                  temp.image_status = true;
                }
                return temp;
              }
            );
            this.$store.commit("custom/group_montring", filterOnlineMembers);
          }
        }
      );
    },
  },
};
</script>
<style scoped>
.refresh-icon {
  float: right;
  padding: 2%;
  cursor: pointer;
}
.img-style {
}
.dot-online {
  background-color: #1ce21c;
  width: 18px;
  height: 18px;
  position: absolute;
  z-index: 1;
  border: 3px solid #ffffff;
  border-radius: 100%;
  right: 45px;
  top: 13px;
}
.dot-task-active {
  background-color: #f7b12e;
}
</style>
